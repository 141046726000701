import { Exchange, SSRExchange, cacheExchange, fetchExchange } from '@urql/core';
import { NextUrqlClientConfig } from 'next-urql';

import getGraphQLEndpoint from '@/lib/getGraphQLEndpoint';
import PublicEnvironment from '@/stores/PublicEnvironment';

function exchanges(ssrExchange: SSRExchange, extraExchanges: Exchange[]) {
  const exchanges = [cacheExchange, ssrExchange, ...extraExchanges];
  if (process.env.NODE_ENV === 'production') {
    return exchanges;
  } else {
    /* eslint-disable-next-line @typescript-eslint/no-var-requires */
    return [require('@urql/devtools').devtoolsExchange, ...exchanges];
  }
}

export default function generateGraphqlConfig(): NextUrqlClientConfig {
  return (ssrExchange: SSRExchange) => ({
    url: getGraphQLEndpoint(),
    exchanges: exchanges(ssrExchange, [fetchExchange]),
    fetchOptions: {
      headers: {
        Authorization:
          typeof window === 'undefined'
            ? `Bearer ${process.env.GRAPHQL_TOKEN?.replace(/"/g, '') || 'missingtoken'}`
            : undefined,
        'gcdn-debug': PublicEnvironment.get('NEXT_PUBLIC_ENV') === 'production' ? undefined : '1',
      } as HeadersInit,
    },
  });
}
