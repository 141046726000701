import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'urql';

import seoNoRedirectQuery from '@/graphql/queries/seoNoRedirect.graphql';
import ParseSeo from '@/lib/ParseHTMLReactHead';

// replaces /graphql and /actions/graphql/api if they exist
const replaceWithUriOn404 = (src: string, uri: string) => src.replace(/(\/actions)?(\/graphql)(\/api)?/g, uri);

export default function useSEO(uriParam?: string) {
  const router = useRouter();
  const uri = encodeURI(uriParam || router.asPath.replace(/\?.*$/, ''));
  const [{ data }] = useQuery<SeoNoRedirectQuery>({
    query: seoNoRedirectQuery,
    variables: { uri, site: router?.locale || 'mymoto' },
  });

  const seo = useMemo(() => {
    const seo = {
      metaTitleContainer: data?.seomatic?.metaTitleContainer ?? '',
      metaTagContainer: data?.seomatic?.metaTagContainer ?? '',
      metaLinkContainer: data?.seomatic?.metaLinkContainer ?? '',
      metaScriptContainer: data?.seomatic?.metaScriptContainer ?? '',
      metaJsonLdContainer: data?.seomatic?.metaJsonLdContainer ?? '',
      metaSiteVarsContainer: data?.seomatic?.metaSiteVarsContainer ?? '',
      headScripts: '',
      bodyScripts: '',
    };

    // ensure that canonical links (and other metadata) doesn't include /graphql on 404
    Object.entries(seo)
      .filter(([key]) => key.startsWith('meta'))
      .forEach(([key, value]) => {
        seo[key as keyof typeof seo] = replaceWithUriOn404(value, uri);
      });

    if (seo.metaScriptContainer) {
      const scriptObject: SEOMaticMetaScriptObject = JSON.parse(seo.metaScriptContainer);
      seo.headScripts = scriptObject.script ?? '';
      seo.bodyScripts = scriptObject.bodyScript ?? '';
    }

    return seo;
  }, [data?.seomatic, uri]);

  return [
    seo ? ParseSeo(seo.metaTitleContainer + seo.metaTagContainer + seo.metaLinkContainer) : null,
    seo ? ParseSeo(seo.metaJsonLdContainer + seo.headScripts) : null,
  ];
}
