import React from 'react';
import { observer } from 'mobx-react';

import Image from '@/components/Image';
import useStore from '@/hooks/useStore';

import Default from '@/images/logo/logo.svg';

import styles from './styles.module.scss';

interface Props {
  type?: 'light' | 'dark';
  /** should include link to homepage (`'/'`); default true */
  link?: boolean;
}

const Logo = ({ type = 'light', link = true }: Props) => {
  const {
    general: { logo, logoDark },
    siteName,
  } = useStore('globals');
  const RootNode = link ? 'a' : 'span';
  return (
    <RootNode href={link ? '/' : undefined} className={styles.logo}>
      {type === 'light' ? (
        logo ? (
          <Image preload loading="eager" {...(logo as ImageObject)} title={siteName} data-testid="imgLogo" />
        ) : (
          <Default />
        )
      ) : logoDark ? (
        <Image preload loading="eager" {...(logoDark as ImageObject)} title={siteName} data-testid="imgLogo" />
      ) : (
        <Default />
      )}
    </RootNode>
  );
};

export default observer(Logo);
