import React from 'react';
import Head from 'next/head';

type Props = Maybe<Partial<AssetFragment>> & {
  mobileWebp?: Maybe<string>;
  'data-testid'?: string;
  preload?: boolean;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, keyof AssetFragment>;

const Image = (props: Props) => {
  /* istanbul ignore next */
  if (!props) {
    /* istanbul ignore next */
    return null;
  }

  const { webp, mobileWebp, jpg, width, height, title, 'data-testid': testid, loading, preload, ...imgProps } = props;
  return (
    <>
      {preload && jpg && (
        <Head>
          <link as="image" rel="preload" href={jpg} key={jpg} />
        </Head>
      )}
      <picture data-testid={testid}>
        {mobileWebp && <source srcSet={mobileWebp} type="image/webp" media="(max-width: 768px)" />}
        {webp && <source srcSet={webp} type="image/webp" />}
        <img
          loading={loading ? loading : 'lazy'}
          src={jpg!}
          alt={title!}
          width={width!}
          height={height!}
          className="image"
          {...imgProps}
        />
      </picture>
    </>
  );
};

export default Image;
