import React, { ElementType } from 'react';
import HTMLReactParser, { Element, HTMLReactParserOptions, attributesToProps, domToReact } from 'html-react-parser';
import Script from 'next/script';

/*
 Leveraging NextJS' 'key' prop: https://nextjs.org/docs/api-reference/next/head
 Applies a key attribute onto meta and link tags while parsing to react elements
 Earliest duplicate key provided is removed.

 Allows for manual meta/link overrides, or duplicate SEO to be applied.

 To override in <Head> use a key that matches either name -> property -> rel (in that priority)
 For example:
  <link rel="canonical" href=".." />
        ^ uses rel
  to override:
  <link key="canonical" ... />
  --------
  <meta name="robots" ... />
        ^ uses name
  to override:
  <meta key="robots" ...>
  -------
  <meta property="og:test" name="test"/>
                            ^ uses name (higher priority than property)

  Special cases / notes:
  <title> will always grab last (key not needed, this is a next/head thing)
  scripts are converted to next/script components. These do NOT have an ID associated wit them,
    so duplicate scripts can be rendered (if called twice)
*/

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.attribs && (domNode.name === 'link' || domNode.name === 'meta')) {
      const Tag: React.ElementType = domNode.name as ElementType<any>;
      if (domNode.children.length) {
        return (
          <Tag
            key={domNode.attribs.name ?? domNode.attribs.property ?? domNode.attribs.rel ?? undefined}
            {...attributesToProps(domNode.attribs)}
          >
            {domToReact(domNode.children)}
          </Tag>
        );
      }
      return (
        <Tag
          key={domNode.attribs.name ?? domNode.attribs.property ?? domNode.attribs.rel ?? undefined}
          {...attributesToProps(domNode.attribs)}
        />
      );
    }
    if (domNode instanceof Element && domNode.name === 'script') {
      // technically this should include an id prop to stop duplication
      return (
        <Script {...attributesToProps(domNode.attribs)}>
          {domNode.children.length && domToReact(domNode.children)}
        </Script>
      );
    }
    return;
  },
};

export default function ParseSeo(seo: string) {
  return HTMLReactParser(seo, options);
}
