import React from 'react';
import cc from 'classcat';

import styles from './styles.module.scss';

const Button = (
  { children, type = 'button', as, size, styleOverride = '', noborder = false, ...props }: ButtonProps,
  ref: React.Ref<HTMLButtonElement | HTMLInputElement>,
) => {
  const Wrapper = props.href ? 'a' : 'button';

  let classNames = cc({
    [styleOverride]: Boolean(styleOverride),
    [styles.button]: true,
    [styles.disabled]: props.disabled,
    [styles.noborder]: noborder,
  });

  if (type && styles[type]) {
    classNames = cc([classNames, styles[type]]);
  }
  if (size && styles[size]) {
    classNames = cc([classNames, styles[size]]);
  }

  return (
    <Wrapper
      type={as || (props.href ? undefined : type)}
      role="button"
      tabIndex={0}
      className={classNames}
      ref={ref}
      {...(props as any)}
    >
      {children}
    </Wrapper>
  );
};

export default React.forwardRef(Button);
