import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import type DealershipsMap from '@/stores/DealershipsMap';
import DirectoryStore from '@/stores/DirectoryStore';
import type Globals from '@/stores/Globals';
import type PublicEnvironment from '@/stores/PublicEnvironment';
import type ShortlistStore from '@/stores/ShortlistStore';

function useStore(store: 'appState'): AppState;
function useStore(store: 'globals'): Globals;
function useStore(store: 'directoryStore'): DirectoryStore;
function useStore(store: 'dealershipsMap'): DealershipsMap;
function useStore(store: 'shortlistStore'): ShortlistStore;
function useStore(store: 'publicEnvironment'): PublicEnvironment;
function useStore<T>(store: string): T;
function useStore<T>(store: string): T {
  const stores = useContext(MobXProviderContext);
  if (!stores || !stores[store]) {
    throw new Error(`useStore must be used within a StoreProvider (${store} was not available).`);
  }
  return stores[store];
}

export default useStore;
