import { useRouter } from 'next/router';
import { UseQueryArgs, UseQueryResponse, useQuery } from 'urql';

type PossibleSiteVariables = { site?: Nullable<string> | Nullable<string>[] };

/**
 * Automatically appends locale to the
 * Note: Variables and Data have swapped order from `useQuery`, this is as Data is optional generic type.
 * If your generic type DOES NOT MATCH with PossibleSiteVariables, either add it or fix your typing.
 */
export default function useSiteQuery<Variables extends PossibleSiteVariables, Data = any>(
  args: UseQueryArgs<Variables, Data>,
  key = 'site',
): UseQueryResponse<Data, Variables> {
  const router = useRouter();

  return useQuery<Data, Variables>({
    ...args,
    variables: { ...args.variables, [key]: router.locale } as Variables,
  });
}
