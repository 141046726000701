import { GetServerSidePropsContext } from 'next';
import { initUrqlClient, withUrqlClient } from 'next-urql';
import { Client, SSRExchange, ssrExchange } from 'urql';

import generateGraphqlConfig from '@/config/graphqlConfig';

function createClient(ctx?: GetServerSidePropsContext): [Client, SSRExchange | null] {
  const ssrCacheInstance = ssrExchange({ isClient: false });
  const options = generateGraphqlConfig()(ssrCacheInstance!);

  if (ctx && ctx.preview && typeof ctx.previewData === 'object' && 'token' in ctx.previewData) {
    options.url = `${options?.url}?token=${ctx.previewData['token']}&t=${new Date().getTime()}`;

    // Assign headers to exclude from caching
    if (typeof options.fetchOptions !== 'function' && options.fetchOptions?.headers) {
      options.fetchOptions.headers = {
        ...options.fetchOptions.headers,
        'Cache-Control': 'no-cache',
        'x-no-cache': '1',
      };
    }
  }

  return [initUrqlClient(options, false), ssrCacheInstance];
}

export { createClient };

export const withUrql = withUrqlClient(generateGraphqlConfig(), { ssr: false });
