import PublicEnvironment from '@/stores/PublicEnvironment';

/**
 * Determine the GraphQL URL
 *
 * - For a mocked GraphQL server, use localhost:4000 (@graphql-yoga/node)
 * - For development, use the stellate container (unless $STELLATE_DISABLED is "true")
 * - For other environments, use the remote stellate endpoint
 */
export default function getGraphQLEndpoint() {
  if (PublicEnvironment.get('MOCK_GRAPHQL_SERVER')) {
    const hostname = process.env.HOSTNAME || '0.0.0.0';
    return `http://${hostname}:4000/graphql`;
  }

  if (PublicEnvironment.get('STELLATE_DISABLED')) {
    return typeof window !== 'undefined'
      ? `${window.location.origin}/graphql`
      : `http://${PublicEnvironment.get('NGINX_HOST') || 'localhost'}/graphql`;
  }

  if (PublicEnvironment.get('ENV') === 'development') {
    return typeof window === 'undefined' ? 'http://stellate:3010' : `${window.location.origin}:3010`;
  }

  return `https://mymoto-${PublicEnvironment.get('NEXT_PUBLIC_ENV')}.stellate.sh`;
}
